import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { Link } from '@remix-run/react'
import React from 'react'

export default function RouteError({
 errorStatus,
 errorStatusText,
 errorDetails,
}) {
 return (
  <>
   <main className="px-6 lg:px-8">
    <div className="mt-10 grid place-items-center justify-center gap-x-6">
     <ExclamationCircleIcon className="h-48 w-48 text-gray-300" />
     <p className="text-base font-semibold text-redOrange-800">{errorStatus}</p>
     <h1 className="text-1xl mt-4 tracking-tight text-gray-900 lg:text-2xl">
      {errorStatusText}
     </h1>
     <p>{errorDetails}</p>
    </div>
    <div className="mt-10 flex items-center justify-center gap-x-6">
     <Link
      to="/"
      className="w-auto rounded-md bg-warmTerracota-700 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-warmTerracota-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-warmTerracota-600"
     >
      Go back Home
     </Link>
    </div>
   </main>
  </>
 )
}
